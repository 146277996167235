import { create } from 'zustand';
import { persist } from 'zustand/middleware';

const useNotificationStore = create(
  persist(
    (set) => ({
      notification: null,
      setNotification: (message, type) =>
        set({ notification: { message, type } }),
      clearNotification: () => set({ notification: null }),
    }),
    { name: 'notification-store' }
  )
);

export default useNotificationStore;
