import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import organizationAPI from 'api/api';
import { getBaseUrl } from 'api/config';
import { VerticalToggleButton } from 'components/CustomMUI';
import useDeepgram from 'hooks/useDeepgram';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuthStore } from 'store';
import usePersonaStore from 'store/personaStore';
import Chatbox from './Chatbox';
import DeepgramCall from './DeepgramCall';
const OngoingCall = () => {
  const token = organizationAPI.getTokenFromLocalStorage();
  const user = useAuthStore((state) => state.loggedUser);
  const [personaObj, setPersonaObj] = useState({
    personaId: null,
    mood: null,
    tone: null,
    difficulty: null,
    objectives: null,
  });
  const baseUrl = getBaseUrl();
  const socketUrl = baseUrl;
  const [isMuted, setIsMuted] = useState(false);
  const location = useLocation();
  const personaId = location.pathname.split('/').pop();
  const navigate = useNavigate();
  const [isTranscriptVisible, setIsTranscriptVisible] = useState(true);
  const { personaBehavior, objectiveId } = usePersonaStore();

  const handleBackClick = () => {
    navigate(-1);
  };

  const toggleTranscript = () => {
    setIsTranscriptVisible(!isTranscriptVisible);
  };

  useEffect(() => {
    const updatedPersonaObj = {
      personaId: personaId,
      mood: null,
      tone: null,
      difficulty: null,
      objectives: null,
    };
    if (personaBehavior) {
      updatedPersonaObj.mood = personaBehavior.mood?.id || null;
      updatedPersonaObj.tone = personaBehavior.tone?.id || null;
      updatedPersonaObj.difficulty = personaBehavior.difficulty?.id || null;
    }

    if (objectiveId) {
      updatedPersonaObj.objectives = objectiveId;
    }

    setPersonaObj(updatedPersonaObj);
  }, [personaBehavior, personaId, objectiveId]);

  const {
    isRecording,
    transcript,
    toggleRecording,
    playAudio,
    responseData,
    isPlayingAudio,
    isIdle,
    error,
    docId,
    saveRecording,
    emitPageLeave,
  } = useDeepgram(token, socketUrl, isMuted, personaObj);
  
  return (
    <>
      <Box
        sx={{
          width: '100%',
          bgcolor: '#f3f8f8',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Container
          sx={{
            padding: '0 35px',
            width: '95%',
            maxWidth: '1600px !important',
            bgcolor: '#f3f8f8',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                gap: '10px',
                alignItems: 'center',
                width: '100%',
                height: '60px',
                justifyContent: 'space-between',
              }}
            >
              <Typography
                variant="h4"
                sx={{
                  color: '#243030',
                  fontFamily: 'Lexend',
                  fontSize: '28px',
                  fontWeight: 300,
                  letterSpacing: '-0.087px',
                }}
              >
                AI Roleplay Call
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '10px',
                }}
              >
                <Button
                  variant="outlined"
                  onClick={handleBackClick}
                  startIcon={<ChevronLeftIcon />}
                  sx={{
                    border: '1px solid #00706c',
                    padding: '0 15px',
                    borderRadius: '5px',
                    height: '43px',
                    color: '#00706c',
                    fontFamily: 'Lexend',
                    fontSize: '16px',
                    fontWeight: 500,
                    textTransform: 'none',
                    '&:hover': {
                      bgcolor: '#2aa87e',
                      color: '#fff',
                    },
                  }}
                >
                  Return
                </Button>
              </Box>
            </Box>
            <Divider
              sx={{
                border: '1px solid #dae8e7',
                my: '10px',
                mb: '30px',
              }}
            />
          </Box>

          <Paper
            sx={{
              mt: '30px',
              mb: '30px',
              width: '100%',
              bgcolor: '#fff',
              p: '20px',
              display: 'flex',
              flexDirection: 'column',
              gap: '40px',
              boxShadow: 'none',
              overflow: 'hidden',
            }}
            elevation={0}
          >
            <Box
              sx={{
                display: 'flex',
                width: '100%',
                height: '100%',
                gap: '20px',
                position: 'relative',
              }}
            >
              <Box
                sx={{
                  flex: isTranscriptVisible ? 'none' : 1,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  p: '0 30px',
                  gap: '20px',
                  position: 'relative',
                  minHeight: '500px',
                  maxHeight: '650px',
                  width: '100%',
                  maxWidth: isTranscriptVisible ? '550px' : 'calc(100% - 80px)',
                  transition: 'all 0.5s ease',
                }}
              >
                <DeepgramCall
                  toggleRecording={toggleRecording}
                  transcript={transcript}
                  playAudio={playAudio}
                  isRecording={isRecording}
                  isPlayingAudio={isPlayingAudio}
                  setIsMuted={setIsMuted}
                  isMuted={isMuted}
                  isIdle={isIdle}
                  error={error}
                  docId={docId}
                  user={user}
                  saveRecording={saveRecording}
                  emitPageLeave={emitPageLeave}
                />
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  position: 'relative',
                  minHeight: '500px',
                  maxHeight: '650px',
                  width: '40px',
                  justifyContent: isTranscriptVisible
                    ? 'flex-start'
                    : 'flex-end',
                }}
              >
                <VerticalToggleButton
                  isOpen={isTranscriptVisible}
                  onChange={toggleTranscript}
                />
              </Box>
              {isTranscriptVisible && (
                <Box
                  sx={{
                    flex: 1,
                    position: 'relative',
                    borderRadius: '8px',
                    p: '0 10px',
                    minHeight: '500px',
                    maxHeight: '650px',
                    maxWidth: '550px',
                    width: '100%',
                  }}
                >
                  <Chatbox data={responseData} />
                </Box>
              )}
            </Box>
          </Paper>
        </Container>
      </Box>
    </>
  );
};

export default OngoingCall;
