import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import {
  Avatar,
  Box,
  Button,
  Chip,
  FormControl,
  Link,
  MenuItem,
  Select,
  Tooltip,
  Typography,
} from '@mui/material';
import organizationAPI from 'api/api';
import { useApi } from 'hooks';
import { useEffect, useState } from 'react';
import usePersonaStore from 'store/personaStore';
import useStore from 'store/useStore';
import { difficultyColors, moodColors, toneColors } from 'utils/status-color';

const ObjectiveDropdown = () => {
  const [objectives, setObjectives] = useState([]);
  const [selectedObjective, setSelectedObjective] = useState('');
  const { call } = useApi({
    fetcher: organizationAPI.getObjectives,
  });
  const { setObjectiveId } = usePersonaStore();
  const { render, clearRender } = useStore();

  const fetchObjectivesData = async () => {
    const response = await call();
    if (response && response?.data.length > 0) {
      if (render) {
        setSelectedObjective(response.data[0].id);
      }
      setObjectives((prevObjectives) => {
        if (JSON.stringify(prevObjectives) !== JSON.stringify(response.data)) {
          return response.data;
        }
        return prevObjectives;
      });
    }
  };

  useEffect(() => {
    if (render) {
      clearRender();
    }
    fetchObjectivesData();
  }, [render]);

  useEffect(() => {
    if (selectedObjective) {
      setObjectiveId(selectedObjective);
    }
  }, [selectedObjective, setObjectiveId]);

  const handleObjectiveChange = (event) => {
    setSelectedObjective(event.target.value);
  };

  const truncateText = (text, maxLength) => {
    if (text.length <= maxLength) return text;
    return text.slice(0, maxLength) + '...';
  };

  const currentObjective = objectives.find(
    (obj) => obj.id === selectedObjective
  );

  if (objectives && objectives.length > 0) {
    return (
      <Box sx={{ width: '100%' }}>
        <Box sx={{ width: '100%' }}>
          <Typography
            sx={{
              color: '#92a7a5',
              fontSize: 14,
              fontWeight: 500,
              fontFamily: 'Lexend',
              textWrap: 'nowrap',
              backgroundColor: '#fff',
              width: 'fit-content',
              zIndex: 1,
              position: 'relative',
              ml: 1,
            }}
          >
            SELECT CALL OBJECTIVE SET
          </Typography>
          <FormControl
            sx={{
              width: '100%',
              mt: '-12px',
              zIndex: 0,
            }}
          >
            <Select
              sx={{
                height: '40px',
              }}
              value={selectedObjective}
              onChange={handleObjectiveChange}
              MenuProps={{
                PaperProps: {
                  style: {
                    marginTop: 10,
                    maxHeight: 300,
                    overflowY: 'auto',
                    width: '450px',
                  },
                },
              }}
            >
              {!selectedObjective && (
                <MenuItem value="" disabled>
                  Select an Objective
                </MenuItem>
              )}
              {objectives.map((item) => (
                <MenuItem
                  value={item.id}
                  key={item.id}
                  sx={{
                    maxWidth: '100%',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {' '}
                  <Tooltip title={item.name} placement="top" arrow>
                    {truncateText(item.name, 55)}{' '}
                  </Tooltip>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        {currentObjective && (
          <Box
            sx={{
              mt: 2,
            }}
          >
            {currentObjective.objectives.map((objective, index) => (
              <Typography
                key={index}
                sx={{
                  fontSize: 14,
                  color: '#666',
                  mb: 0.5,
                  display: 'flex',
                  '&:before': {
                    content: `"${index + 1}."`,
                    minWidth: '20px',
                    marginRight: '4px',
                  },
                }}
              >
                {objective}
              </Typography>
            ))}
          </Box>
        )}
      </Box>
    );
  } else {
    return null;
  }
};
const SelectedPersona = () => {
  const { selectedPersona, personaBehavior } = usePersonaStore();
  const maleImage = '/male.jpg';
  const femaleImage = '/female.jpg';
  if (selectedPersona) {
    const imageUrl =
      selectedPersona.avatar && selectedPersona.avatar !== 'invalid'
        ? selectedPersona.avatar
        : selectedPersona.gender === 'male'
        ? maleImage
        : femaleImage;

    return (
      <Box
        sx={{
          height: '95%',
          width: '97%',
          padding: '10px',
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          gap: '20px',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Typography variant="h5" sx={{ color: '#243030', fontWeight: 300 }}>
          Selected Buyer Persona
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '20px',
            alignItems: 'center',
            width: '90%',
          }}
        >
          <Avatar
            src={imageUrl}
            alt={selectedPersona.name}
            sx={{ width: 128, height: 128 }}
          />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <Typography variant="h5" sx={{ color: '#00706c', fontWeight: 300 }}>
              {selectedPersona.name}
            </Typography>
            <Typography
              sx={{ color: '#5b706f', fontSize: 14, fontWeight: 300 }}
            >
              {selectedPersona.role}
            </Typography>
            {selectedPersona.company && (
              <Typography
                sx={{ color: '#5b706f', fontSize: 14, fontWeight: 300 }}
              >
                @ {selectedPersona.company}
              </Typography>
            )}
            {!personaBehavior ||
              (Object.values(personaBehavior).every(
                (value) => value !== '' || value !== null
              ) && (
                <Box sx={{ display: 'flex', gap: 1, mt: 1 }}>
                  {['mood', 'tone', 'difficulty'].map((type) => {
                    const colorObj =
                      type === 'mood'
                        ? moodColors
                        : type === 'tone'
                        ? toneColors
                        : difficultyColors;
                    const color =
                      colorObj[personaBehavior[type].name.toLowerCase()] ||
                      colorObj.default;
                    return (
                      <Chip
                        key={type}
                        label={personaBehavior[type].name}
                        sx={{
                          color: color,
                          border: `1px solid ${color}`,
                          textTransform: 'capitalize',
                          backgroundColor: '#fff',
                        }}
                      />
                    );
                  })}
                </Box>
              ))}
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: '10px',
              width: '100%',
              mt: 2,
            }}
          >
            <Typography variant="h6" sx={{ color: '#243030' }}>
              Persona Brief
            </Typography>
            <Typography
              sx={{ color: '#5b706f', fontSize: 14, textAlign: 'center' }}
            >
              {selectedPersona.description}
            </Typography>
          </Box>
          <ObjectiveDropdown />
        </Box>
        <Button
          component={Link}
          href={`/airoleplaycall/ongoingcall/${selectedPersona._id}`}
          startIcon={<LocalPhoneIcon />}
          variant="contained"
          sx={{
            width: '100%',
            py: 2,
            backgroundColor: '#00706c',
            '&:hover': {
              backgroundColor: '#04413e',
            },
          }}
        >
          Start Call
        </Button>
      </Box>
    );
  } else {
    return (
      <Box
        sx={{
          height: '100%',
          width: '97%',
          padding: '10px',
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          gap: '20px',
          alignItems: 'center',
          justifyContent: 'space-between',
          maxHeight: '80vh',
        }}
      >
        <Box
          sx={{
            width: '100%',
            height: 30,
            bgcolor: '#e0e0e0',
            borderRadius: 1,
            animation: 'pulse 1.5s infinite ease-in-out',
          }}
        />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '20px',
            alignItems: 'center',
            width: '60%',
          }}
        >
          <Box
            sx={{
              width: 128,
              height: 128,
              borderRadius: '50%',
              bgcolor: '#e0e0e0',
              animation: 'pulse 1.5s infinite ease-in-out',
            }}
          />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: 1,
            }}
          >
            <Box
              sx={{
                width: '60%',
                height: 20,
                bgcolor: '#e0e0e0',
                borderRadius: 1,
                animation: 'pulse 1.5s infinite ease-in-out',
              }}
            />
            <Box
              sx={{
                width: '40%',
                height: 20,
                bgcolor: '#e0e0e0',
                borderRadius: 1,
                animation: 'pulse 1.5s infinite ease-in-out',
              }}
            />
            <Box
              sx={{
                width: '80%',
                height: 20,
                bgcolor: '#e0e0e0',
                borderRadius: 1,
                animation: 'pulse 1.5s infinite ease-in-out',
              }}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: '10px',
              width: '100%',
              mt: 2,
            }}
          >
            <Box
              sx={{
                width: '50%',
                height: 20,
                bgcolor: '#e0e0e0',
                borderRadius: 1,
                animation: 'pulse 1.5s infinite ease-in-out',
              }}
            />
            <Box
              sx={{
                width: '90%',
                height: 20,
                bgcolor: '#e0e0e0',
                borderRadius: 1,
                animation: 'pulse 1.5s infinite ease-in-out',
              }}
            />
          </Box>
        </Box>
        <Button
          disabled
          startIcon={<LocalPhoneIcon />}
          sx={{
            width: '100%',
            py: 2,
            bgcolor: '#c2c0c0',
            color: '#fff',
            opacity: 0.5,
            '&:hover': {
              bgcolor: '#c2c0c0',
            },
          }}
        >
          Start Call
        </Button>
      </Box>
    );
  }
};

export default SelectedPersona;
