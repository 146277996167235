import CallEndIcon from '@mui/icons-material/CallEnd';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import organizationAPI from 'api/api';
import { callDuration } from 'api/config';
import {
  Caller,
  DeviceSelector,
  DynamicLoadingChecklist,
} from 'components/CustomMUI';
import { useSnackbar } from 'contexts/SnackbarContext';
import { useApi } from 'hooks';
import useMicDetection from 'hooks/useMicDetection';
import useTimer from 'hooks/useTimer';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import usePersonaStore from 'store/personaStore';
import useNotificationStore from 'store/useNotificationStore';
import useStore from 'store/useStore';

const DeepgramCall = ({
  transcript,
  toggleRecording,
  playAudio,
  isRecording,
  isPlayingAudio,
  setIsMuted,
  isMuted,
  isIdle,
  error,
  docId,
  user,
  saveRecording,
  emitPageLeave,
}) => {
  const { formattedTime, stopTimer } = useTimer();
  const { selectedPersona, setDocId, personaBehavior } = usePersonaStore();
  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar();
  const { setNotification } = useNotificationStore();
  const [isLoading, setIsLoading] = useState(false);
  const { isSpeaking, isIdle: userIsIdle } = useMicDetection();
  const [disabled, setDisabled] = useState(false);
  const { pathName, clearPathName, setCallEnded, callEnded, clearCallEnded } =
    useStore();
  const [isAssignmentPage, setIsAssignmentPage] = useState(false);

  const { call: ConvRubricCall } = useApi({
    fetcher: organizationAPI.generateConvRubric,
    successMessage: 'Record generated successfully!',
  });

  const { call: PeerComparisonCall } = useApi({
    fetcher: organizationAPI.genratePeerComparison,
  });

  const { call: transcriptCall } = useApi({
    fetcher: organizationAPI.getConvRubric,
  });

  const { call: ConvoAssignmentCall } = useApi({
    fetcher: organizationAPI.updateConvoAssignment,
  });

  const [checklistItems, setChecklistItems] = useState([
    {
      id: 'convRubric',
      label: 'Conversation Rubric Generated',
      isComplete: false,
      apiCall: ConvRubricCall,
    },
    {
      id: 'peerComparison',
      label: 'Peer Comparison Generated',
      isComplete: false,
      apiCall: PeerComparisonCall,
    },
    {
      id: 'transcript',
      label: 'Transcript Fetched',
      isComplete: false,
      apiCall: transcriptCall,
    },
  ]);

  const maleImage = '/male.jpg';
  const femaleImage = '/female.jpg';

  const imageUrl =
    selectedPersona.avatar && selectedPersona.avatar !== 'invalid'
      ? selectedPersona.avatar
      : selectedPersona.gender === 'male'
      ? maleImage
      : femaleImage;

  useEffect(() => {
    if (pathName.includes('/assignment')) {
      setIsAssignmentPage(true);
    } else {
      setIsAssignmentPage(false);
    }
    if (callEnded) {
      clearCallEnded();
    }
  }, []);

  useEffect(() => {
    if (isIdle) {
      handleCallEnd();
      stopTimer();
      setNotification(
        'Something went wrong. Please try again. ' || error,
        'error'
      );
    }
  }, [isIdle, navigate, error]);

  const updateChecklistItem = (itemId, isComplete) => {
    setChecklistItems((prevItems) =>
      prevItems.map((item) =>
        item.id === itemId ? { ...item, isComplete } : item
      )
    );
  };

  useEffect(() => {
    return () => {
      emitPageLeave();
    };
  }, [emitPageLeave]);

  const handleCallEnd = async () => {
    if (!docId) {
      setDisabled(true);
      navigate(-1);
      return;
    }
    setCallEnded();
    setIsMuted(true);
    setDisabled(true);
    setDocId(docId);
    const data = { docId };
    setIsLoading(true);

    try {
      await saveRecording();
      const convRubricResponse = await checklistItems[0].apiCall(data);
      if (!convRubricResponse) {
        setNotification(
          `Scorecard not generated. The call was too short. a conversation must be active for at least ${
            callDuration / 1000
          } seconds to generate a scorecard.`,
          'warning'
        );
        throw new Error(
          `Scorecard not generated. The call was too short. a conversation must be active for at least ${
            callDuration / 1000
          } seconds to generate a scorecard.`
        );
      }
      updateChecklistItem('convRubric', true);

      const peerComparisonResponse = await checklistItems[1].apiCall(data);
      if (!peerComparisonResponse) {
        setNotification('Failed to generate peer comparison response', 'error');
        throw new Error('Failed to generate peer comparison response');
      }
      updateChecklistItem('peerComparison', true);

      const transcriptResponse = await checklistItems[2].apiCall(docId);
      if (!transcriptResponse) {
        setNotification(
          `Transcript not generated. The call was too short. a conversation must be active for at least ${
            callDuration / 1000
          } seconds to generate a transcript.`,
          'warning'
        );
        throw new Error(
          `Transcript not generated. The call was too short. a conversation must be active for at least ${
            callDuration / 1000
          } seconds to generate a transcript.`
        );
      }
      updateChecklistItem('transcript', true);

      if (isAssignmentPage) {
        try {
          const tempData = {
            data: { personaId: selectedPersona._id },
            userAssignmentId: selectedPersona.userAssignmentId,
          };

          const convoAssignmentResponse = await ConvoAssignmentCall(tempData);
        } catch (error) {
          console.log(error);
        }
      }

      emitPageLeave();
      setTimeout(() => {
        setIsLoading(false);
        clearPathName();
        navigate(`/airoleplaycall/calldetails`);
      }, 1000);
    } catch (err) {
      setIsLoading(false);
      const errorMessage =
        err.message || 'Something went wrong. Please try again.';

      if (errorMessage.includes('Call duration was too short')) {
        showSnackbar(
          `The call was too short. a conversation must be active for at least ${
            callDuration / 1000
          } seconds to generate a scorecard.`
        );
        setNotification(
          `The call was too short. a conversation must be active for at least ${
            callDuration / 1000
          } seconds to generate a scorecard.`,
          'warning'
        );
      } else {
        showSnackbar(errorMessage);
        setNotification(errorMessage, 'error');
      }
      emitPageLeave();
      clearPathName();
      navigate(-1);
    }
  };

  useEffect(() => {
    if (!isSpeaking && userIsIdle && !disabled) {
      setIsLoading(false);
      setNotification(
        'User is idle for too long. Please try again.',
        'warning'
      );
      clearPathName();
      setCallEnded();
      navigate(-1);
    }
  }, [isSpeaking, userIsIdle, disabled]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '20px',
        padding: '20px',
        border: '1px solid #dae8e7',
        borderRadius: '5px',
        width: '100%',
        height: '100%',
        overflow: 'hidden',
        position: 'relative',
        justifyContent: 'space-between',
      }}
    >
      <DynamicLoadingChecklist
        isLoading={isLoading}
        checklistItems={checklistItems}
        title="Processing Call Data..."
      />
      <Typography
        variant="h4"
        sx={{ color: '#243030', fontWeight: 300, fontSize: '24px' }}
      >
        Ongoing Call
      </Typography>
      <Grid container spacing={2} justifyContent="space-evenly">
        {user && (
          <Grid item xs={12} sm={6}>
            <Caller
              image={'/user.jpg'}
              userName={`${user.firstName} ${user.lastName}`}
              designation={'Sales Development Representative'}
              handle={user.organizationName}
              status={'Sales Representative'}
              bgColor={'#0088FF'}
              borderColor={'#0088FF'}
              toggleRecording={toggleRecording}
              playAudio={playAudio}
              isRecording={isRecording}
            />
          </Grid>
        )}
        <Grid item xs={12} sm={6}>
          <Caller
            image={imageUrl}
            userName={selectedPersona.name}
            designation={selectedPersona.role}
            handle={selectedPersona.company}
            status={`${selectedPersona.type} Persona`}
            bgColor={'#00706C'}
            borderColor={'#00706C'}
            isPersona={true}
            isPlayingAudio={isPlayingAudio}
            setIsMuted={setIsMuted}
            isMuted={isMuted}
          />
        </Grid>
      </Grid>
      <DeviceSelector />
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          gap: '10px',
        }}
      >
        <Paper
          elevation={0}
          sx={{
            width: '97%',
            padding: '10px',
            backgroundColor: '#f3f8f8',
            borderRadius: '5px',
            overflow: 'hidden',
          }}
        >
          <Typography
            sx={{
              fontFamily: 'Lexend',
              fontSize: '14px',
              fontWeight: 300,
              color: '#525050',
              textAlign: 'justify',
              whiteSpace: 'nowrap',
              overflowX: 'auto',
              overflowY: 'hidden',
              wordWrap: 'normal',
              scrollbarWidth: 'none',
              '&::-webkit-scrollbar': {
                display: 'none',
              },
            }}
          >
            {transcript}
          </Typography>
        </Paper>
        <Button
          variant="contained"
          onClick={() => {
            stopTimer();
            handleCallEnd();
          }}
          disabled={disabled}
          sx={{
            width: '100%',
            backgroundColor: '#e03838',
            height: '60px',
            borderRadius: '8px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '5px 20px',
            color: '#fff',
            '&:hover': {
              backgroundColor: '#ac1515',
            },
            '&:disabled': {
              backgroundColor: '#b2b2b2',
            },
          }}
        >
          <Typography
            sx={{ fontFamily: 'Lexend', fontSize: '14px', fontWeight: 500 }}
          >
            {formattedTime}
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
            <CallEndIcon />
            <Typography
              sx={{ fontFamily: 'Lexend', fontSize: '14px', fontWeight: 500 }}
            >
              End Call
            </Typography>
          </Box>
        </Button>
      </Box>
    </Box>
  );
};

export default DeepgramCall;
