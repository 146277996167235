import organizationAPI from 'api/api';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import io from 'socket.io-client';
import usePersonaStore from 'store/personaStore';
import useStore from 'store/useStore';

const useDeepgram = (token, socketUrl, isMuted, personaObj) => {
  const [isRecording, setIsRecording] = useState(false);
  const [transcript, setTranscript] = useState('');
  const [audioBuffer, setAudioBuffer] = useState(null);
  const socketRef = useRef(null);
  const audioContextRef = useRef(null);
  const sourceNodeRef = useRef(null);
  const [llmResponse, setLlmResponse] = useState('');
  const [aiGeneratedResponse, setAiGeneratedResponse] = useState('');
  const [humanResponse, setHumanResponse] = useState('');
  const [isIdle, setIsIdle] = useState(false);
  const [responseData, setResponseData] = useState([]);
  const [isPlayingAudio, setIsPlayingAudio] = useState(false);
  const [docId, setDocId] = useState('');
  const [error, setError] = useState(null);
  const { selectedPersona } = usePersonaStore();
  const audioChunksRef = useRef([]);
  const { callEnded, clearCallEnded } = useStore();
  const combinedAudioBuffersRef = useRef([]);
  const [isSaving, setIsSaving] = useState(false);
  const microphoneRef = useRef(null);
  const maleImage = '/male.jpg';
  const femaleImage = '/female.jpg';
  const mediaStreamRef = useRef(null);
  const [isSpeaking, setIsSpeaking] = useState(false);
  const gainNodeRef = useRef(null);
  const imageUrl =
    selectedPersona.avatar && selectedPersona.avatar !== 'invalid'
      ? selectedPersona.avatar
      : selectedPersona.gender === 'male'
      ? maleImage
      : femaleImage;

  const formatTime = useMemo(
    () => (timestamp) => {
      const date = new Date(timestamp);
      const minutes = String(date.getUTCMinutes()).padStart(2, '0');
      const seconds = String(date.getUTCSeconds()).padStart(2, '0');
      return `${minutes}:${seconds}`;
    },
    []
  );

  const removeSilence = useCallback(
    (audioBuffer, threshold = 0.005, minSilenceDuration = 0.3) => {
      const numberOfChannels = audioBuffer.numberOfChannels;
      const sampleRate = audioBuffer.sampleRate;
      const silenceThresholds = Array.from(
        { length: numberOfChannels },
        (_, channel) => {
          const channelData = audioBuffer.getChannelData(channel);
          const maxAmplitude = channelData.reduce(
            (max, value) => Math.max(max, Math.abs(value)),
            0
          );
          return threshold * maxAmplitude;
        }
      );

      const minSilenceSamples = minSilenceDuration * sampleRate;

      let nonSilentRanges = [];
      let silenceStart = 0;
      let isInSilence = true;

      for (let i = 0; i < audioBuffer.length; i++) {
        const isSilent = silenceThresholds.every(
          (silenceThreshold, channel) => {
            const amplitude = Math.abs(audioBuffer.getChannelData(channel)[i]);
            return amplitude <= silenceThreshold;
          }
        );

        if (!isSilent) {
          if (isInSilence) {
            if (i - silenceStart >= minSilenceSamples) {
              nonSilentRanges.push([i, i]);
            } else {
              nonSilentRanges.push([silenceStart, i]);
            }
            isInSilence = false;
          }
          nonSilentRanges[nonSilentRanges.length - 1][1] = i;
        } else if (!isInSilence) {
          silenceStart = i;
          isInSilence = true;
        }
      }

      const totalNonSilentSamples = nonSilentRanges.reduce(
        (sum, [start, end]) => sum + (end - start + 1),
        0
      );
      const newBuffer = new AudioContext().createBuffer(
        numberOfChannels,
        totalNonSilentSamples,
        sampleRate
      );

      let offset = 0;
      for (const [start, end] of nonSilentRanges) {
        for (let channel = 0; channel < numberOfChannels; channel++) {
          const channelData = audioBuffer.getChannelData(channel);
          newBuffer.copyToChannel(
            channelData.subarray(start, end + 1),
            channel,
            offset
          );
        }
        offset += end - start + 1;
      }

      return newBuffer;
    },
    []
  );

  const initializeSocket = useCallback(() => {
    if (
      (!socketRef.current && personaObj) ||
      (!socketRef.current.connected && personaObj)
    ) {
      socketRef.current = io(socketUrl, {
        transports: ['websocket'],
        auth: { token },
        reconnectionDelay: 1000,
        reconnectionAttempts: 5,
      });

      socketRef.current.on('connect', () => {
        console.log(
          `client: connected to websocket, with id: ${socketRef.current.id}`
        );
        socketRef.current.emit('persona-id', personaObj);
      });

      socketRef.current.on('connect_error', (err) => {
        setError({ type: 'SOCKET_CONNECTION_ERROR', message: err.message });
      });
      socketRef.current.on('transcript', (newTranscript) => {
        if (newTranscript !== '') {
          setTranscript(newTranscript);
        }
      });

      socketRef.current.on('llm-response', async (data) => {
        if (data) {
          setLlmResponse(data);
          await fetchAndProcessAudio(data.answer);
        }
      });

      socketRef.current.on('ai-generated-answer', async (data) => {
        if (data) {
          setAiGeneratedResponse(data);
        }
      });

      socketRef.current.on('is-idle', async (data) => {
        if (data) {
          setIsIdle(data);
        }
      });

      socketRef.current.on('is-speaking', async (data) => {
        if (data) {
          setIsSpeaking(data);
        }
      });

      socketRef.current.on('human-response', async (data) => {
        if (data) {
          setHumanResponse(data);
        }
      });
      socketRef.current.on('doc-id', async (data) => {
        if (data) {
          setDocId(data);
        }
      });

      socketRef.current.on('microphone-pause', closeMicrophone);
      socketRef.current.on('microphone-resume', resumeMicrophone);

      socketRef.current.on('error', (error) => {
        setError({ type: 'SOCKET_ERROR', message: error.message });
      });

      audioContextRef.current = new (window.AudioContext ||
        window.webkitAudioContext)();
    }
  }, [socketUrl, token, personaObj]);

  useEffect(() => {
    initializeSocket();

    return () => {
      if (socketRef.current) {
        socketRef.current.disconnect();
        console.log('Socket disconnected');
      }

      if (
        audioContextRef.current &&
        audioContextRef.current.state !== 'closed'
      ) {
        audioContextRef.current.close();
        console.log('AudioContext closed');
      }
    };
  }, [initializeSocket]);

  useEffect(() => {
    const addResponseData = (newData) => {
      setResponseData((prevData) => {
        const exists = prevData.some(
          (item) =>
            item.message === newData.message && item.time === newData.time
        );
        return exists ? prevData : [...prevData, newData];
      });
    };

    if (llmResponse) {
      addResponseData({
        type: 'llm-response',
        userAvatar: imageUrl,
        time: formatTime(llmResponse.time),
        message: llmResponse.answer,
      });
    }
    if (humanResponse) {
      addResponseData({
        type: 'human-response',
        userAvatar: '/user.jpg',
        time: formatTime(humanResponse.time),
        message: humanResponse.answer,
        aiGeneratedResponse: {
          type: 'ai-generated-answer',
          time: formatTime(aiGeneratedResponse.time),
          message: aiGeneratedResponse.answer,
          score: aiGeneratedResponse.score,
        },
      });
    }
  }, [llmResponse, humanResponse, aiGeneratedResponse, imageUrl, formatTime]);

  const emitPageLeave = useCallback(() => {
    // if (socketRef.current && socketRef.current.connected) {
    //   console.log('Emitting call-ended event on page change');
    //   socketRef.current.emit('call-ended');
    // }
    console.log('Emitting call-ended event on page change');
    socketRef.current.emit('call-ended');
  }, []);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      emitPageLeave();
    };

    const handlePopState = () => {
      emitPageLeave();
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      window.removeEventListener('popstate', handlePopState);
    };
  }, [emitPageLeave]);

  const getMicrophone = useCallback(async () => {
    if (!mediaStreamRef.current) {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: {
            echoCancellation: true,
            noiseSuppression: true,
            autoGainControl: true,
          },
        });
        mediaStreamRef.current = stream;
      } catch (error) {
        setError({ type: 'MICROPHONE_ACCESS_ERROR', message: error.message });
        throw error;
      }
    }
    return new MediaRecorder(mediaStreamRef.current, {
      mimeType: 'audio/webm',
    });
  }, []);

  const stopRecording = useCallback(() => {
    if (mediaStreamRef.current) {
      mediaStreamRef.current.getAudioTracks().forEach((track) => {
        track.enabled = false;
      });
      console.log('client: microphone muted');
    }

    setIsRecording(false);
  }, []);

  const openMicrophone = useCallback(async () => {
    try {
      if (mediaStreamRef.current) {
        mediaStreamRef.current.getAudioTracks().forEach((track) => {
          track.enabled = true;
        });
        console.log('client: microphone unmuted');
      }
      if (!microphoneRef.current) {
        const mic = await getMicrophone();
        microphoneRef.current = mic;
      }

      microphoneRef.current.start(100);

      microphoneRef.current.onstart = () => {
        console.log('client: microphone opened');
        audioChunksRef.current = [];
      };

      microphoneRef.current.ondataavailable = async (e) => {
        if (socketRef.current && socketRef.current.connected) {
          console.log('client: sent valid audio data to websocket');
          socketRef.current.emit('packet-sent', e.data);
          audioChunksRef.current.push(e.data);
        } else {
          console.log('client: socket not connected, audio data not sent');
        }
      };
    } catch (error) {
      setError({ type: 'MICROPHONE_OPEN_ERROR', message: error.message });
    }
  }, [getMicrophone]);

  const closeMicrophone = useCallback(async () => {
    if (microphoneRef.current && microphoneRef.current.state === 'recording') {
      microphoneRef.current.stop();
    }
    setIsRecording(false);
  }, []);

  useEffect(() => {
    if (socketRef.current) {
      socketRef.current.emit('call-ended', callEnded);
      if (callEnded) {
        closeMicrophone();
      }
    }
  }, [callEnded, closeMicrophone, clearCallEnded]);

  const resumeMicrophone = useCallback(async () => {
    try {
      if (!mediaStreamRef.current) {
        await openMicrophone();
      } else {
        microphoneRef.current.start(100);
      }
      console.log('client: resuming microphone');
      setIsRecording(true);
    } catch (error) {
      setError({ type: 'MICROPHONE_RESUME_ERROR', message: error.message });
    }
  }, [mediaStreamRef, openMicrophone]);

  const toggleRecording = useCallback(() => {
    if (isRecording) {
      stopRecording();
    } else {
      setIsRecording(true);
      openMicrophone();
    }
  }, [isRecording, openMicrophone, stopRecording]);

  const processUserAudio = useCallback(async () => {
    if (audioChunksRef.current.length > 0) {
      try {
        const userAudioBlob = new Blob(audioChunksRef.current, {
          type: 'audio/webm',
        });
        const userAudioArrayBuffer = await userAudioBlob.arrayBuffer();
        const userAudioBuffer = await audioContextRef.current.decodeAudioData(
          userAudioArrayBuffer
        );
        // const filteredAudioBuffer = removeSilence(userAudioBuffer);
        combinedAudioBuffersRef.current.push(userAudioBuffer);
        console.log(
          'user buffer added',
          combinedAudioBuffersRef.current.length
        );
        audioChunksRef.current = [audioChunksRef.current[0]];
        console.log('User audio processed and added to combined buffers');
      } catch (error) {
        console.error('Error processing user audio:', error);
        setError({ type: 'PROCESS_USER_AUDIO_ERROR', message: error.message });
      }
    }
  }, []);

  const fetchAndProcessAudio = useCallback(
    async (text) => {
      try {
        const response = await fetch(
          `${socketUrl}/api/v1/conversationalAI/getAudio`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + token,
            },
            body: JSON.stringify({ text, model: selectedPersona.model }),
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const arrayBuffer = await response.arrayBuffer();

        const decodedAudio = await audioContextRef.current.decodeAudioData(
          arrayBuffer
        );

        setAudioBuffer(decodedAudio);
        await processUserAudio();

        combinedAudioBuffersRef.current.push(decodedAudio);
        console.log(
          'server buffer added',
          combinedAudioBuffersRef.current.length
        );

        console.log('Server audio processed and added to combined buffers');
      } catch (error) {
        console.error('Error fetching and processing audio:', error);
        setError({ type: 'FETCH_AUDIO_ERROR', message: error.message });
      }
    },
    [socketUrl, token, processUserAudio]
  );

  const combineAudioBuffers = useCallback(async (buffers) => {
    if (buffers.length === 0) return null;
    if (buffers.length === 1) return buffers[0];

    const audioContext = new (window.AudioContext ||
      window.webkitAudioContext)();

    buffers.forEach((buffer, index) => {
      console.log(`Buffer ${index} sample rate:`, buffer.sampleRate);
    });

    const targetSampleRate = audioContext.sampleRate;

    const resampledBuffers = await Promise.all(
      buffers.map(async (buffer) => {
        if (buffer.sampleRate !== targetSampleRate) {
          return await resampleAudioBuffer(buffer, targetSampleRate);
        }
        return buffer;
      })
    );

    const totalLength = resampledBuffers.reduce(
      (sum, buffer) => sum + buffer.length,
      0
    );

    const numberOfChannels = Math.max(
      ...resampledBuffers.map((buffer) => buffer.numberOfChannels)
    );

    const combinedBuffer = audioContext.createBuffer(
      numberOfChannels,
      totalLength,
      targetSampleRate
    );

    let offset = 0;
    for (const buffer of resampledBuffers) {
      for (let channel = 0; channel < numberOfChannels; channel++) {
        const channelData = combinedBuffer.getChannelData(channel);
        if (channel < buffer.numberOfChannels) {
          channelData.set(buffer.getChannelData(channel), offset);
        }
      }
      offset += buffer.length;
    }

    return combinedBuffer;
  }, []);

  async function resampleAudioBuffer(audioBuffer, targetSampleRate) {
    const audioContext = new (window.AudioContext ||
      window.webkitAudioContext)();
    const sourceSampleRate = audioBuffer.sampleRate;
    const offlineCtx = new OfflineAudioContext(
      audioBuffer.numberOfChannels,
      Math.ceil(audioBuffer.length * (targetSampleRate / sourceSampleRate)),
      targetSampleRate
    );

    const source = offlineCtx.createBufferSource();
    source.buffer = audioBuffer;
    source.connect(offlineCtx.destination);
    source.start();

    return await offlineCtx.startRendering();
  }

  const audioBufferToWav = useCallback((buffer) => {
    const numberOfChannels = buffer.numberOfChannels;
    const sampleRate = buffer.sampleRate;
    const length = buffer.length * numberOfChannels * 2;
    const arrayBuffer = new ArrayBuffer(44 + length);
    const view = new DataView(arrayBuffer);

    const writeString = (view, offset, string) => {
      for (let i = 0; i < string.length; i++) {
        view.setUint8(offset + i, string.charCodeAt(i));
      }
    };

    writeString(view, 0, 'RIFF');

    view.setUint32(4, 36 + length, true);
    writeString(view, 8, 'WAVE');
    writeString(view, 12, 'fmt ');
    view.setUint32(16, 16, true);
    view.setUint16(20, 1, true);
    view.setUint16(22, numberOfChannels, true);
    view.setUint32(24, sampleRate, true);
    view.setUint32(28, sampleRate * numberOfChannels * 2, true);
    view.setUint16(32, numberOfChannels * 2, true);
    view.setUint16(34, 16, true);
    writeString(view, 36, 'data');
    view.setUint32(40, length, true);

    const channelData = [];
    for (let i = 0; i < numberOfChannels; i++) {
      channelData.push(buffer.getChannelData(i));
    }

    let offset = 44;
    for (let i = 0; i < buffer.length; i++) {
      for (let channel = 0; channel < numberOfChannels; channel++) {
        const sample = Math.max(-1, Math.min(1, channelData[channel][i]));
        view.setInt16(
          offset,
          sample < 0 ? sample * 0x8000 : sample * 0x7fff,
          true
        );
        offset += 2;
      }
    }

    return arrayBuffer;
  }, []);

  const saveRecording = useCallback(async () => {
    if (isSaving) {
      console.log('Already saving, please wait...');
      return;
    }

    setIsSaving(true);
    try {
      console.log('Saving recording...');
      await closeMicrophone();
      console.log(
        `Combining ${combinedAudioBuffersRef.current.length} audio buffers`
      );
      const finalCombinedBuffer = await combineAudioBuffers(
        combinedAudioBuffersRef.current
      );
      const filteredAudioBuffer = removeSilence(finalCombinedBuffer);
      if (filteredAudioBuffer) {
        console.log('Creating WAV file...');
        const wavBuffer = await audioBufferToWav(filteredAudioBuffer);
        const combinedBlob = new Blob([wavBuffer], { type: 'audio/wav' });

        console.log('Preparing file for upload...');
        const formData = new FormData();
        const fileName = `conversation-${Date.now()}.wav`;
        formData.append('file', combinedBlob, fileName);
        formData.append('docId', docId);

        console.log('Uploading file...', { fileName, docId });
        const response = await organizationAPI.uploadRecording({
          data: formData,
        });

        console.log('Upload response:', response);

        if (response && response.status === 200) {
          console.log('Recording uploaded successfully');

          // --------------------------- local download start---------------------------
          // const url = URL.createObjectURL(combinedBlob);
          // const a = document.createElement('a');
          // a.href = url;
          // a.download = `conversation-${Date.now()}.wav`;
          // document.body.appendChild(a);
          // a.click();
          // setTimeout(() => {
          //   document.body.removeChild(a);
          //   URL.revokeObjectURL(url);
          // }, 100);
          // --------------------------- local download end   ---------------------------

          combinedAudioBuffersRef.current = [];
        } else {
          throw new Error(
            `Upload failed with status: ${response?.status || 'unknown'}`
          );
        }
      } else {
        console.log('No audio data to save');
      }
    } catch (error) {
      console.error('Failed to save recording:', error);
      setError({
        type: 'SAVE_RECORDING_ERROR',
        message:
          error.message ||
          'An unknown error occurred while saving the recording',
      });
    } finally {
      setIsSaving(false);
    }
  }, [closeMicrophone, combineAudioBuffers, isSaving, audioBufferToWav, docId]);

  const playAudio = useCallback(() => {
    if (audioBuffer && audioContextRef.current) {
      try {
        if (audioContextRef.current.state === 'suspended') {
          audioContextRef.current.resume();
        }

        if (!sourceNodeRef.current) {
          // Create and start the audio source if it doesn't exist
          sourceNodeRef.current = audioContextRef.current.createBufferSource();
          const gainNode = audioContextRef.current.createGain();

          // Store the gainNode in a ref so we can update it dynamically
          gainNodeRef.current = gainNode; // Store gainNode for updates

          gainNode.gain.value = isMuted ? 0 : 1;
          sourceNodeRef.current.buffer = audioBuffer;
          sourceNodeRef.current
            .connect(gainNode)
            .connect(audioContextRef.current.destination);

          setIsPlayingAudio(true);
          sourceNodeRef.current.start();

          sourceNodeRef.current.onended = () => {
            setIsPlayingAudio(false);
            sourceNodeRef.current = null; // Cleanup when audio ends
          };
        } else {
          // Update the gain value to mute/unmute dynamically without restarting
          if (gainNodeRef.current) {
            gainNodeRef.current.gain.value = isMuted ? 0 : 1;
          }
        }
      } catch (error) {
        setError({ type: 'PLAY_AUDIO_ERROR', message: error.message });
      }
    }
  }, [audioBuffer, isMuted]);

  return {
    isRecording,
    transcript,
    toggleRecording,
    playAudio,
    hasAudio: !!audioBuffer,
    isIdle,
    isSpeaking,
    responseData,
    isPlayingAudio,
    error,
    docId,
    audioBuffer,
    saveRecording,
    isSaving,
    emitPageLeave,
  };
};

export default useDeepgram;
//Test
